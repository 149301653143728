<template>
  <table v-if="loaded" class="table">
    <thead>
      <tr>
        <th scope="col">
          Documents
        </th>
        <th scope="col">
          Filing method
        </th>
        <th scope="col">
          Type
        </th>
        <th scope="col">
          State Fee
          <fa-icon
            v-b-popover.hover.top="'This is the cost you should expect to pay the state upon filing'"
            class="question-circle pl-1 help-text"
            icon="question-circle"
            size="1x"
          />
        </th>
        <th scope="col" class="text-center" />
      </tr>
    </thead>
    <tbody id="documentTable">
      <template v-for="(filing) in sortedFilings">
        <template v-for="(filingMethod, indexFilingMethod) in filingMethodsFor(filing.id)">
          <documents-table-row
            :filing="filing"
            :is-show-type="isShowType(filingMethodsFor(filing.id),indexFilingMethod,filingMethod)"
            :filing-method="filingMethod"
            :key="filingMethod.id"
            :index="indexFilingMethod"
          />
        </template>
        <tr :key="filing.id" >
          <td colspan="5">
&nbsp;
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <div v-else>
    <ct-centered-spinner />
  </div>
</template>

<script>
  import DocumentsTableRow from '@/components/Filing/DocumentTableRow'
  import CtCenteredSpinner from '../shared/CtCenteredSpinner'

  export default {
    name: 'DocumentsTable',
    components: {
      DocumentsTableRow,
      CtCenteredSpinner,
    },
    props: {
      filings: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        loaded: false,
        filingMethods: null,
        sortedFilings: null,
      }
    },
    async mounted() {
      const response = await this.$store.dispatch('filingMethods/fetchFilingMethods', {
        params: {
          filingId: this.filings.map(a => a.id),
        },
      })
      this.filingMethods = response.data
      this.sortFilings()
      this.loaded = true
    },
    methods: {
      filingMethodsFor(filing_id) {
        return this.filingMethods.filter(fm => fm.filingId == filing_id).sort((a, b) => a.type < b.type)
      },
      sortFilings() {
        this.sortedFilings = this.filings.sort((a, b) => a.description > b.description)
      },
      // I'm totally not sure about this hack
      isShowType(filingMethods, index, filingMethod){
        if(index === 0){
          return true
        }else{
          return filingMethods[index - 1].type != filingMethod.type
        }
      },
    },
  }
</script>
