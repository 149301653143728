<template>
  <tr>
    <td style="word-break: keep-all">
      {{ filingName }}
    </td>
    <td style="text-transform:capitalize" class="filing_table_row">
      {{ filingMethodType }}
    </td>
    <td class="filing_table_row">
      {{ formatEstimateForDisplay }}
    </td>
    <td class="filing_table_row">
      ${{ filingMethod.cost }}
    </td>
    <td>
      <b-link aria-label="select link" @click="methodSelected">
        Select
      </b-link>
    </td>
  </tr>
</template>

<script>
  export default {
    name: 'DocumentsTableRow',
    props: {
      filing: {
        type: Object,
        required: true,
      },
      filingMethod: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      isShowType: {
        type: Boolean,
        required: true,
      },
    },
    computed:{
      formatEstimateForDisplay () {
        let days = this.filingMethod.filedIn.days
        let hours = this.filingMethod.filedIn.hours
        let format = ''
        if (days === 0 || !days) {
          format = hours + ' hours'
        } else if (hours === 0 || !hours) {
          format = days + ' days'
        } else {
          format = days + ' days and ' + hours + ' hours'
        }
        return format
      },
      filingName(){
        return this.index == 0 ? this.filing.description : ''
      },
      filingMethodType(){
        if (this.isShowType) {
          return this.filingMethod.type
        } else {
          return ''
        }
      },
    },
    methods: {
      methodSelected() {
        this.$router.push({
          name: 'resourceSelection',
          params: {
            filingMethodId: this.filingMethod.id,
            filingId: this.filing.id,
          },
        })
      },
    },
  }
</script>

<style lang='scss'>
  .filing_table_row {
    width: 12%
  }
</style>
