var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n          Documents\n        "),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n          Filing method\n        "),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n          Type\n        "),
            ]),
            _c(
              "th",
              { attrs: { scope: "col" } },
              [
                _vm._v("\n          State Fee\n          "),
                _c("fa-icon", {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top",
                      value:
                        "This is the cost you should expect to pay the state upon filing",
                      expression:
                        "'This is the cost you should expect to pay the state upon filing'",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  staticClass: "question-circle pl-1 help-text",
                  attrs: { icon: "question-circle", size: "1x" },
                }),
              ],
              1
            ),
            _c("th", { staticClass: "text-center", attrs: { scope: "col" } }),
          ]),
        ]),
        _c(
          "tbody",
          { attrs: { id: "documentTable" } },
          [
            _vm._l(_vm.sortedFilings, function (filing) {
              return [
                _vm._l(
                  _vm.filingMethodsFor(filing.id),
                  function (filingMethod, indexFilingMethod) {
                    return [
                      _c("documents-table-row", {
                        key: filingMethod.id,
                        attrs: {
                          filing: filing,
                          "is-show-type": _vm.isShowType(
                            _vm.filingMethodsFor(filing.id),
                            indexFilingMethod,
                            filingMethod
                          ),
                          "filing-method": filingMethod,
                          index: indexFilingMethod,
                        },
                      }),
                    ]
                  }
                ),
                _c("tr", { key: filing.id }, [
                  _c("td", { attrs: { colspan: "5" } }, [
                    _vm._v("\n \n          "),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ])
    : _c("div", [_c("ct-centered-spinner")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }