<template>
  <b-container class="mt-2">
    <masthead :show-search="false">
      <b-button variant="link" :class="$mq != 'sm' ? 'btn-shadow mr-2' : ''" @click="goBack">
        <feather-icon type="chevron-left" />
        Back
      </b-button>
    </masthead>
    <template v-if="loaded">
      <h2>{{ companyName }} - Documents for {{ jurisdictionName }}</h2>
      <b-tabs v-model="tabIndex" content-class="mt-3">
        <b-tab title="Domestic Filing">
          <documents-table :filings="domestic" />
        </b-tab>
        <b-tab title="Foreign Filings">
          <documents-table :filings="foreign" />
        </b-tab>
      </b-tabs>
    </template>
    <div v-else>
      <ct-centered-spinner data-cy="busyIndicator" />
    </div>
  </b-container>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import Masthead from '@/components/shared/Masthead'
import DocumentsTable from '@/components/Filing/DocumentsTable'
import CtCenteredSpinner from '../../components/shared/CtCenteredSpinner'

export default {
  name: 'JurisdictionFilings',
  components: {
    DocumentsTable,
    FeatherIcon,
    Masthead,
    CtCenteredSpinner,
  },
  data() {
    return {
      loaded: false,
      filings: [],
      domestic: [],
      foreign: [],
      company: null,
      jurisdiction: null,
      tabIndex: 0,
    }
  },
  computed: {
    companyName(){
      return this.company.name
    },
    jurisdictionName(){
      return this.jurisdiction.stateProvinceRegion
    },
  },
  async mounted () {
    await this.receiveFilings()
    await this.selectFilingsByLocality()
    await this.receiveCompany()
    await this.receiveJurisdiction()

    this.loaded = true
  },
  methods: {
    async receiveFilings() {
      this.filings = await this.$store.dispatch('companies/fetchCompanyFilings', {
        params: {
          jurisdiction: this.$route.params.jurisdictionId,
          company: this.$route.params.companyId,
        },
      }).then((response) => _.sortBy(response, 'name').find(a => !!a).filter(f => f.name !== 'free form a company'))
    },
    async receiveCompany() {
      this.company = await this.$store.dispatch('companies/fetchId', {
        id: this.$route.params.companyId,
      })
    },
    async receiveJurisdiction() {
      this.jurisdiction  = await this.$store.dispatch('jurisdictions/fetchId', {
        id: this.$route.params.jurisdictionId,
      })
    },
    async selectFilingsByLocality() {
      this.filings.forEach(item => {
        switch(item.locality) {
          case 'both':
            this.domestic.push(item)
            this.foreign.push(item)
            break
          case 'domestic':
            this.domestic.push(item)
            break
          case 'foreign':
            this.foreign.push(item)
        }
      })
    },

    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style>
  .nav-item .nav-link, .nav-item .nav-link:focus, .nav.nav-tabs{
    border: none;
  }
</style>

