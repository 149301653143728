var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2" },
    [
      _c(
        "masthead",
        { attrs: { "show-search": false } },
        [
          _c(
            "b-button",
            {
              class: _vm.$mq != "sm" ? "btn-shadow mr-2" : "",
              attrs: { variant: "link" },
              on: { click: _vm.goBack },
            },
            [
              _c("feather-icon", { attrs: { type: "chevron-left" } }),
              _vm._v("\n      Back\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loaded
        ? [
            _c("h2", [
              _vm._v(
                _vm._s(_vm.companyName) +
                  " - Documents for " +
                  _vm._s(_vm.jurisdictionName)
              ),
            ]),
            _c(
              "b-tabs",
              {
                attrs: { "content-class": "mt-3" },
                model: {
                  value: _vm.tabIndex,
                  callback: function ($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex",
                },
              },
              [
                _c(
                  "b-tab",
                  { attrs: { title: "Domestic Filing" } },
                  [_c("documents-table", { attrs: { filings: _vm.domestic } })],
                  1
                ),
                _c(
                  "b-tab",
                  { attrs: { title: "Foreign Filings" } },
                  [_c("documents-table", { attrs: { filings: _vm.foreign } })],
                  1
                ),
              ],
              1
            ),
          ]
        : _c(
            "div",
            [
              _c("ct-centered-spinner", {
                attrs: { "data-cy": "busyIndicator" },
              }),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }